<template>
  <v-dialog v-model="visibility" width="720px" persistent>
    <v-card style="padding: 16px">
      <v-row no-gutters align="center">
        <p class="sectionTitle" style="margin: 0px">
          {{ isEdit ? "Оновити тег" : "Новий те" }}
        </p>
        <v-row no-gutters align="center" justify="end" v-if="isEdit">
          <v-switch inset v-model="tag.is_visible" />
        </v-row>
      </v-row>

      <div class="sectionLine" />
      <v-text-field
        outlined
        dense
        label="Назва тегу"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="tag.name"
        :error-messages="nameError"
      />
      <v-text-field
        outlined
        dense
        label="Назва тегу англійською"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="tag.name_en"
        :error-messages="nameENError"
      />
      <v-text-field
        outlined
        dense
        label="Назва тегу польською"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="tag.name_pl"
        :error-messages="namePLError"
      />
      <submit-button
        style="
          width: 100%;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          height: 48px !important;
        "
        @click="isEdit ? updateTag() : createNewTag()"
        >{{ isEdit ? "Оновити" : "Додати" }}
      </submit-button>
      <v-row no-gutters justify="center" style="margin-top: 10px">
        <cancel-btn text="Скасувати" @click="$emit('close')" />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import modalMixin from "../../../mixins/modalMixin";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../UI/Buttons/submitButton.vue";
import { required } from "vuelidate/lib/validators";
import contentService from "./../../../requests/Content/contentService.js";
import requestFormData from "../../../requests/requestFormData";
export default {
  components: { submitButton, CancelBtn },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    tag: {
      name: "",
      name_en: "",
      name_pl: "",
    },
  }),
  props: {
    isEdit: { require: false },
    itemForEdit: {
      require: false,
    },
  },
  validations: {
    tag: {
      name: {
        required,
      },
      name_en: {
        required,
      },
      name_pl: {
        required,
      },
    },
  },
  mounted() {
    if (this.isEdit) {
      this.setTagForEdit();
    }
  },
  methods: {
    async createNewTag() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          is_visible: "Yes",
          translations: [
            {
              lang: "ua",
              slug: this.tag.name,
              name: this.tag.name,
            },
            {
              lang: "en",
              slug: `${this.tag.name_en}_en`,
              name: this.tag.name_en,
            },
            {
              lang: "pl",
              slug: `${this.tag.name_pl}_pl`,
              name: this.tag.name_pl,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await contentService.createCategory(form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    async updateTag() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          is_visible: this.tag.is_visible ? "Yes" : "No",
          translations: [
            {
              id: this.tag.translation_id,
              lang: "ua",
              slug: this.tag.slug,
              name: this.tag.name,
            },
            {
              id: this.tag.translation_id_en,
              lang: "en",
              slug: `${this.tag.slug_en}_en`,
              name: this.tag.name_en,
            },
            {
              id: this.tag.translation_id_pl,
              lang: "pl",
              slug: `${this.tag.slug_pl}`,
              name: this.tag.name_pl,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await contentService.updateCategory(this.tag.id, form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    setTagForEdit() {
      let translate = this.itemForEdit.translations.find(
        (translt) => translt.lang == "ua"
      );
      let translate_en = this.itemForEdit.translations.find(
        (translt) => translt.lang == "en"
      );
      let translate_pl = this.itemForEdit.translations.find(
        (translt) => translt.lang == "pl"
      );
      this.$set(this.tag, "id", this.itemForEdit.id);
      this.$set(
        this.tag,
        "is_visible",
        this.itemForEdit.is_visible == "Yes" ? true : false
      );
      this.$set(this.tag, "translation_id", translate.id);
      this.$set(this.tag, "translation_id_en", translate_en.id);
      this.$set(this.tag, "translation_id_pl", translate_pl.id);
      this.$set(this.tag, "slug", translate.name);
      this.$set(this.tag, "slug_en", translate_en.slug);
      this.$set(this.tag, "slug_pl", translate_pl.slug);
      this.$set(this.tag, "name", translate.name);
      this.$set(this.tag, "name_en", translate_en.name);
      this.$set(this.tag, "name_pl", translate_pl.name);
    },
  },
  computed: {
    nameError() {
      const errors = [];
      let field = this.$v.tag.name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    nameENError() {
      const errors = [];
      let field = this.$v.tag.name_en;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    namePLError() {
      const errors = [];
      let field = this.$v.tag.name_pl;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
  },
};
</script>

<style scoped>
</style>