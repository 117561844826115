import contentService from "../../../requests/Content/contentService";
import requestFormData from "../../../requests/requestFormData";
export default {
    methods: {
        async getContent() {
            await contentService
                .getContentForAdminBySection(this.page, this.setRequestSearchField())
                .then((res) => {
                    if (res.status == "Success") {
                        this.contentList = res.data;
                        this.quantityPage = res.pagination.meta.last_page;
                        this.showLoader = false;
                    }
                });
        },
        async getBlogById() {
            await contentService.getBlogById(this.$route.params.id).then((res) => {
                if (res.status == "Success") {
                    this.setBlogContentForEdit(res.data);
                }
            });
        },
        async getTags() {
            await contentService.getCategories(1).then((res) => {
                if (res.status == "Success") {
                    this.tagList = res.data;
                }
            });
        },
        async getBlogStatistic() {
            await contentService.getBlogStatistic().then((res) => {
                this.statistic = res.data;
            })
        },
        async createBlog() {
            this.$v.$touch();
            if (this.checkedTags.length == 0) {
                this.tagError = ["Виберіть хоча б один тег"];
                return;
            }
            if (!this.$v.$invalid) {
                const data = {
                    is_popular: this.blog.is_popular ? "Yes" : "No",
                    is_important: this.blog.is_important ? "Yes" : "No",
                    images: [this.blog.image.id],
                    categories: this.checkedTags,
                    section: "Blog",
                    translations: [
                        {
                            lang: "ua",
                            title: this.blog.title,
                            content: this.blog.content,
                        },
                        {
                            lang: "en",
                            title: this.blog.title_en ? this.blog.title_en : this.blog.title,
                            content: this.blog.content_en
                                ? this.blog.content_en
                                : this.blog.content,
                        },
                        {
                            lang: "pl",
                            title: this.blog.title_pl ? this.blog.title_pl : this.blog.title,
                            content: this.blog.content_pl
                                ? this.blog.content_pl
                                : this.blog.content,
                        },
                    ],
                };
                let form = requestFormData.jsonToFormData(data);
                await contentService.createContent(form).then((res) => {
                    if (res.status == "Success") {
                        this.$router.push({ name: "blogComponent" });
                    }
                });
            }
        },
        async updateBlog() {
            this.$v.$touch();
            if (this.checkedTags.length == 0) {
                this.tagError = ["Виберіть хоча б один тег"];
                return;
            }
            if (!this.$v.$invalid) {
                const data = {
                    is_popular: this.blog.is_popular ? "Yes" : "No",
                    is_important: this.blog.is_important ? "Yes" : "No",
                    images: [this.blog.image.id],
                    categories: this.checkedTags,
                    section: "Blog",
                    translations: [
                        {
                            id: this.blog.translation_id,
                            lang: "ua",
                            slug: this.blog.slug,
                            title: this.blog.title,
                            content: this.blog.content,
                        },
                        {
                            id: this.blog.translation_id_en,
                            lang: "en",
                            slug: this.blog.slug_en ? this.blog.slug_en : this.blog.slug,
                            title: this.blog.title_en ? this.blog.title_en : this.blog.title,
                            content: this.blog.content_en
                                ? this.blog.content_en
                                : this.blog.content,
                        },
                        {
                            id: this.blog.translation_id_pl,
                            lang: "pl",
                            slug: this.blog.slug_pl ? this.blog.slug_pl : this.blog.slug,
                            title: this.blog.title_pl ? this.blog.title_pl : this.blog.title,
                            content: this.blog.content_pl
                                ? this.blog.content_pl
                                : this.blog.content,
                        },
                    ],
                };
                let form = requestFormData.jsonToFormData(data);
                await contentService.updateContent(this.blog.id, form).then((res) => {
                    if (res.status == "Success") {
                        this.$router.push({ name: "blogComponent" });
                    }
                });
            }
        },
        async changeStatus(id, status) {
            let form = new FormData();
            form.append('status', status)
            await contentService.changeContentStatus(id, form).then((res) => {
                if (res.status == 'Success') {
                    this.getContent()
                }
            })
        },
        async deleteContent(id) {
            await contentService.deleteContent(id).then(() => {
                if (this.$route.name !== 'blogComponent') {
                    this.$router.push({ name: 'blogComponent' })
                } else {
                    this.showLoader = true;
                    this.getContent();
                }
            });
        },
        setRequestSearchField() {
            if (this.activeStatus !== null && this.activeStatus !== undefined && this.activeStatus !== '' && this.activeStatus !== 'All') {
                return `[section[eq]:Blog;status[eq]:${this.activeStatus};all[like]:${this.search}]`
            } else if (this.search !== '' && this.search !== null && this.search !== undefined) {
                return `[section[eq]:Blog;all[like]:${this.search}]`
            }
            return '';
        }
    }
}