<template>
  <v-col class="px-0 py-0">
    <back-btn label="Назад" @click="$router.push({ name: 'blogComponent' })" />
    <statistic-card
      v-if="isEdit"
      :numbers="blog.view_count"
      title="Переглядів"
      subtitle="Усього переглянуто клієнтами"
      style="margin: 20px 0px"
    />
    <v-row no-gutters justify="end" style="margin-top: 32px" v-if="isEdit">
      <div
        class="iconBackground"
        style="margin-right: 12px"
        @click="deleteContent(blog.id)"
      >
        <div class="deleteIcon" />
      </div>
      <div class="iconBackground">
        <div class="archiveIcon" />
      </div>
    </v-row>
    <div class="sectionBackground">
      <p class="sectionTitle">Банер</p>
      <p class="sectionText">Додайте фото, яке буде відповідати новині</p>
      <div class="sectionLine" style="margin: 20px 0px" />
      <div
        style="
          border-radius: 10px;
          border: 1px solid #e2e2e2;
          background: #e7edf6;
          width: 440px;
          height: 340px;
          display: grid;
          place-items: center;
          cursor: pointer;
          user-select: none;
        "
        @click="showImageGalleryModal = true"
      >
        <v-col style="text-align: center" v-if="!blog.image.path">
          <img src="@/assets/img/iconsSvg/imageIcon.svg" />
          <p
            style="
              color: #1b1b1b;
              font-family: 'MacPaw Fixel';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: underline;
              margin-top: 10px;
            "
          >
            Завантажити фото
          </p>
        </v-col>
        <img
          v-else
          :src="blog.image.path"
          alt="blog image"
          width="100%"
          height="100%"
          style="object-fit: cover; border-radius: 10px"
        />
      </div>
      <span v-if="imageError.length > 0" class="error-text">{{
        imageError?.[0]
      }}</span>
    </div>
    <change-lang-form @setLang="setLang" />
    <div class="sectionBackground">
      <p class="sectionTitle">Контент</p>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-text-field
        outlined
        dense
        label="Заголовок"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="titleModel"
        :error-messages="titleError"
      />
      <div
        style="border: 2px solid transparent; border-radius: 5px"
        :style="contentError.length ? 'border: 2px solid #DF3A3A' : ''"
      >
        <vue-editor v-model="contentModel"></vue-editor>
      </div>
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Теги</p>
      <p class="sectionText">Виберіть сферу новин</p>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-row no-gutters align="center">
        <div
          class="tags"
          :class="
            checkedTags.findIndex((id) => id == tag.id) !== -1
              ? 'activeTag'
              : ''
          "
          v-for="tag in tagList"
          :key="tag.id"
          @click="checkTags(tag.id)"
          @dblclick="
            (showCreateNewTagModal = true),
              (isEditTag = true),
              (tagForEdit = tag)
          "
        >
          {{
            tag.translations.find((translate) => translate.lang == "ua")?.name
          }}
        </div>
      </v-row>
      <p
        class="error-text"
        v-if="tagError.length > 0 && this.checkedTags.length == 0"
      >
        {{ tagError?.[0] }}
      </p>
      <add-new-item-btn
        text="Створити новий тег"
        @click="showCreateNewTagModal = true"
      />
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Додаткові можливості</p>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-row no-gutters align="center">
        <v-col cols="11">
          <p style="margin-bottom: 4px; color: #1b1b1b" class="sectionText">
            Популярна новина
          </p>
          <span class="sectionText" style="font-size: 14px"
            >Можливість зробити популярною не на основі переглядів користувачів
            (Тег може включитися автоматично, якщо набере достатню кількість
            переглядів)</span
          >
        </v-col>
        <v-col cols="1"> <v-switch inset v-model="blog.is_popular" /></v-col
      ></v-row>
      <v-row no-gutters align="center" style="margin-top: 20px"
        ><v-col cols="11">
          <p style="margin-bottom: 4px; color: #1b1b1b" class="sectionText">
            Важлива новина
          </p>
          <span class="sectionText" style="font-size: 14px"
            >Додайте, щоб познвчити важливу новину</span
          > </v-col
        ><v-col cols="1"> <v-switch inset v-model="blog.is_important" /></v-col
      ></v-row>
    </div>
    <submit-button
      style="
        height: 48px !important;
        font-size: 20px;
        font-weight: 700;
        margin-top: 60px;
      "
      @click="isEdit ? updateBlog() : createBlog()"
      >Зберегти</submit-button
    >
    <create-new-tag-modal
      v-if="showCreateNewTagModal"
      :visible="showCreateNewTagModal"
      :isEdit="isEditTag"
      :itemForEdit="tagForEdit"
      @successCreate="(showCreateNewTagModal = false), getTags()"
      @close="showCreateNewTagModal = false"
    />
    <image-gallery-modal
      v-if="showImageGalleryModal"
      :type="'Blog'"
      :visible="showImageGalleryModal"
      @chooseImage="chooseImage"
      @close="showImageGalleryModal = false"
    />
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import addNewItemBtn from "../../UI/Buttons/addNewItemBtn.vue";
import CreateNewTagModal from "./createNewTagModal.vue";
import { VueEditor } from "vue2-editor";
import { required } from "vuelidate/lib/validators";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import StatisticCard from "../../UI/Cards/statisticCard.vue";
import BackBtn from "../../UI/Buttons/backBtn.vue";
import ChangeLangForm from "../../UI/Forms/changeLangForm.vue";
import ImageGalleryModal from "../../UI/imageGalleryModal.vue";
import blogMixin from "./blogMixin";
export default {
  components: {
    addNewItemBtn,
    CreateNewTagModal,
    VueEditor,
    SubmitButton,
    StatisticCard,
    BackBtn,
    ChangeLangForm,
    ImageGalleryModal,
  },
  mixins: [validationMixin, blogMixin],
  data: () => ({
    blog: {
      title: "",
      title_en: "",
      title_pl: "",
      content: "",
      content_en: "",
      content_pl: "",
      image: "",
    },
    tagList: [],
    checkedTags: [],
    tagError: [],
    showCreateNewTagModal: false,
    showImageGalleryModal: false,
    activeLang: "ua",
    isEditTag: false,
    isEdit: false,
    tagForEdit: {},
  }),
  validations: {
    blog: {
      title: {
        required,
      },
      content: {
        required,
      },
      image: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.name == "editBlog") {
      this.getBlogById();
      this.isEdit = true;
    }
    this.getTags();
  },
  methods: {
    setLang(lang) {
      this.activeLang = lang;
    },
    checkTags(id) {
      let index = this.checkedTags.findIndex((tag) => tag == id);
      if (index == -1) {
        this.checkedTags.push(id);
      } else {
        this.checkedTags = this.checkedTags.filter((tag) => tag !== id);
      }
    },
    chooseImage(image) {
      this.blog.image = image;
      this.showImageGalleryModal = false;
    },
    setBlogContentForEdit(item) {
      this.$set(this.blog, "id", item.id);
      this.$set(
        this.blog,
        "is_popular",
        item.is_popular == "Yes" ? true : false
      );
      this.$set(
        this.blog,
        "is_important",
        item.is_important == "Yes" ? true : false
      );
      this.$set(this.blog, "published_at", item.published_at);
      this.$set(this.blog, "section", item.section);
      this.$set(this.blog, "view_count", item.view_count);
      this.$set(this.blog, "image", item.images?.[0]);
      item.categories.forEach((category) => {
        this.checkedTags.push(category.id);
      });
      let translate = item.translations.find((translt) => translt.lang == "ua");
      let translate_en = item.translations.find(
        (translt) => translt.lang == "en"
      );
      let translate_pl = item.translations.find(
        (translt) => translt.lang == "pl"
      );
      this.$set(this.blog, "translation_id", translate.id);
      this.$set(this.blog, "translation_id_en", translate_en.id);
      this.$set(this.blog, "translation_id_pl", translate_pl.id);
      this.$set(this.blog, "title", translate.title);
      this.$set(this.blog, "title_en", translate_en.title);
      this.$set(this.blog, "title_pl", translate_en.title);
      this.$set(this.blog, "slug", translate.slug);
      this.$set(this.blog, "slug_en", translate_en.slug);
      this.$set(this.blog, "slug_pl", translate_pl.slug);
      this.$set(this.blog, "content", translate.content);
      this.$set(this.blog, "content_en", translate_en.content);
      this.$set(this.blog, "content_pl", translate_pl.content);
    },
  },
  computed: {
    titleModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.blog.title;
          }
          case "en": {
            return this.blog.title_en;
          }
          case "pl": {
            return this.blog.title_pl;
          }
        }
        return this.faq.question;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.blog.title = val;
            }
            break;
          case "en":
            {
              this.blog.title_en = val;
            }
            break;
          case "pl":
            {
              this.blog.title_pl = val;
            }
            break;
        }
      },
    },
    contentModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.blog.content;
          }
          case "en": {
            return this.blog.content_en;
          }
          case "pl": {
            return this.blog.content_pl;
          }
        }
        return this.faq.question;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.blog.content = val;
            }
            break;
          case "en":
            {
              this.blog.content_en = val;
            }
            break;
          case "pl":
            {
              this.blog.content_pl = val;
            }
            break;
        }
      },
    },
    titleError() {
      const errors = [];
      let field = this.$v.blog.title;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    imageError() {
      const errors = [];
      let field = this.$v.blog.image;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("Додайте фото");
      return errors;
    },
    contentError() {
      const errors = [];
      let field = this.$v.blog.content;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.tags {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  width: max-content;
  padding: 4px 8px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 20px 20px 0px;
  user-select: none;
  cursor: pointer;
}
.activeTag {
  background: #144fa9;
  color: #fafafa;
}
</style>